import Image from 'next/image';
import { useFormat } from 'helpers/hooks/useFormat';
import inProgressImage from './../../public/images/‌inprogress.png';
const InProgress = () => {
    const { formatMessage } = useFormat({ name: 'common' });
    return (<div className="flex flex-col">
      <div className="flex min-h-[100vh] w-full items-center justify-center bg-[#CEF0D250] px-4">
        <div className="flex w-fit flex-col items-center justify-center gap-8 rounded-[10px] bg-[#CEF0D2] p-[60px] max-w-[650px]">
          <h2 className="text-[32px] font-extrabold leading-[30px] text-[#005E57] md:text-[60px] md:leading-[73px]">
            {formatMessage({
            id: 'in.progress.title',
        })}
          </h2>
          <Image loader={(options) => options.src} src={inProgressImage} height={250} width={360} loading="eager" alt="in-progress"/>
          <p className="max-w-[500px] text-[16px] font-normal leading-[20px] text-[#005E57] md:text-[24px] md:leading-[30px]">
            {formatMessage({
            id: 'in.progress.description',
        })}
          </p>
        </div>
      </div>
    </div>);
};
export default InProgress;
